.paperContainer {
	padding: 1vmax;
	margin-top: 41px;
	border-radius: 10px;
}

.headerContainer {
	display: flex;
}

.title {
	color: var(--orange);
	font-size: 1.2vw;
	font: normal 600 24px/24px var(--alt-font);
	letter-spacing: 0.74px;
}

.mainContainer {
	margin-top: 13px;
}

.confirmationHeader {
	font-size: 18px;
}

.subtext {
	margin-top: 2vh;
	font-size: 0.7vw;
}

.successBtn {
	padding: 19px 6px !important;
	min-width: 120px !important;
	font-size: 13px !important;
	height: 40px;
	border-radius: 4px;
}