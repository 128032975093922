.modalSize {
	width: 500px;
}

.successModal {
	width: 372px !important;
	min-height: 293px;
}

.resetModal {
	margin: 15px 20px 0px 20px;
}

.header {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 5px;
	padding-bottom: 22px;
}

.customHeader {
	font-size: 18px;
	text-align: left;
	letter-spacing: 0px;
	color: #1e1e1e;
}

.userVerified {
	color: #14bb02;
	text-align: center;
	font-size: 12px;
	font: italic normal medium 12px/18px Poppins;
	font-style: italic;
	letter-spacing: 0px;
	padding-left: 10px;
}

.passwordReset {
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0px;
	font: normal normal medium 14px/21px Poppins;
	margin-bottom: 5px;
}

.verification {
	padding-top: 15px;
	color: #8b9095;
	font-size: 12px;
}

.footer {
	display: flex;
	justify-content: flex-end;
	gap: 6px;
	padding-bottom: 40px;
	font-size: 14px;
}

.unblockBtn,
.doneButton,
.cancelBtn {
	padding: 19px 6px !important;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
	top: 20px;
}

.dropdown {
	color: rebeccapurple;
	font-size: 100px;
}

.subTextNext {
	margin-bottom: 2vh;
	font-size: 12px;
}

.errorBodyContent {
	padding-left: 15px;
	padding-right: 15px;
}

.errorHeader {
	font-weight: bold;
	margin-bottom: 10px;
	font: normal normal 1.2vmax Poppins-Light;
}

.errorBody {
	font: normal normal 10px/16px Poppins-Light;
}

.errorFooter {
	margin-top: 0;
	margin-bottom: 30px;
	font: normal normal 10px/16px Poppins-Light;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.statusVerifiedText {
	color: #14bb02;
	font-style: italic;
	font-size: 13px;
	margin-left: 15px;
}

.statusUnverifiedText {
	color: #c84031;
	font-style: italic;
	font-size: 13px;
	margin-left: 15px;
}
.bodyHeaderEmphasis {
	font-weight: bold;
}
