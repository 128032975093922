.fontPoppins {
    font-family: Poppins-Regular
}

.fontWavehaus {
    font-family: Wavehaus-SemiBold
}

.mainDiv {
    /*height: 350px;*/
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 3px 10px #00000029;
}

.mask:active,
.mask:hover,
.mask:focus {
    color: #b0adab;
}

.passwordReferenceGuide {
    position: absolute;
    z-index: 999;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    font-size: 10px;
    padding: 20px 45px 45px 20px;
    background-color: #FFFFFF;
}

.passwordInput {
    height: 46px;
}

.passwordInput:not(:placeholder-shown) {
    border: 1px solid black;
}

.errorPasswordInput {
    border: 1px solid red !important;
}

.passwordLabel {
    color: #3B3B3B;
}

button:focus {
    box-shadow: none !important;
}

.passwordInput::placeholder {
    font-size: 12px;
    color: #8B9095;
}
