.container {
	border-radius: 20px;
	padding: 20px;
	box-shadow: 0px 3px 15px #00000024;
}

.viewDetails {
	background-color: Transparent;
	background-repeat: no-repeat;
	border: none;
	cursor: pointer;
	overflow: hidden;
	outline: none;
}

.viewDetailsIcon {
	fill: #f26122;
}

.iconBtn {
	width: 1vmax;
	height: 1vmax;
}

.actionContainer {
	display: inline-flex;
}

.pill_processing {
	color: #f89f7e;
}
.pill_approved {
	color: #6fcf6c;
}
.pill_rejected {
	color: #ec9592;
}
.pill_reprocess {
	color: #caca66;
}

.viewVoidIcon {
	fill: #db2e29;
}

.voidDisabled{ 
	opacity: 0.5
}

.toolTip > div {
	background: #f1f1f1 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 2px;
}

.toolTip > div > div {
	color: #3b3b3b !important;
	font: normal normal 10px/16px Poppins-Light;
}

.disabledLogoButton {
	filter: grayscale(0.92);
}
