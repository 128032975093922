.tagInput {
	border: 1px solid var(--sds-c-card-color-border, #dddbda);
	padding: 0.2vmax;
	border-radius: 5px;
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	align-items: center;
	overflow-y: auto;
	min-height: 33px;
}

.tagInput.hasError {
	border: 2px solid #ea001e;
}

.tagInput.empty {
	padding: 0.3vmax;
	padding-left: 0.8vw;
}

.tagInput:not(.hasError):focus-within {
	border: 1px solid #0077c0;
	box-shadow: var(--sds-c-input-shadow-focus, 0 0 3px #0176d3);
}

.tagInput.hasError:focus-within {
	box-shadow: #ea001e 0 0 0 1px inset, 0 0 3px #0176d3;
}

.tag {
	font-size: var(--input-label-font-size);
	margin: 0.2vh 0.1vw;
	border: 1px solid #0077c0;
	background: #e6f5fe;
	padding: 0 0.3vmax;
	outline: 0;
	width: auto;
	display: flex;
	min-height: 3vh;
}

.tag:hover {
	background: #cfe6f5;
}

.tag:focus,
.tag:active,
.tag.activeTag {
	border: 0.12vmax solid #037cb4;
	font-weight: 600;
}

.tag a,
.tag a:focus,
.tag a:active {
	border: none;
	outline: 0;
	box-shadow: none;
}

.tag :global(.slds-pill__remove) {
	cursor: pointer;
	border: none;
	background-color: transparent;
	color: #3e3f42;
}

.tag :global(.slds-pill__remove svg) {
	color: #aaa;
	width: 0.7vmax;
}

.tag.error :global(.slds-pill__remove svg) {
	fill: #f81c2c;
}

.error {
	border-color: #f81c2c;
	background-color: #f6727c;
	color: black;
}
.error:hover {
	background-color: #f15663;
}
.inputForm {
	flex: 1;
	min-width: 1vw;
}

.inputForm input[type='text'],
.inputForm input[type='text']:focus {
	height: 3vh;
	min-height: 3vh;
	margin: 0;
	font-size: var(--input-label-font-size);
	width: 100%;
	border: none;
	outline: none;
}

.subtext {
	color: #8b9095;
	font-size: 0.6vw;
}

.labelContainer {
	margin-bottom: 1vh;
}

.labelForView {
	margin-bottom: 0.7vh;
}

.labelClassName {
	font: normal normal normal 11px/18px Poppins !important;
	color: #3b3b3b;
}

.label {
	margin-bottom: 0 !important;
}

input[type='text']:disabled {
	cursor: not-allowed;
	background-color: #fff;
}

.helper {
	font-size: var(--input-label-font-size);
}

.fullWidth {
	width: 100%;
}

.disabled {
	border-color: rgb(161, 159, 159);
}

.disabled > a {
	color: rgb(161, 159, 159);
}

.viewTags {
	max-height: 106px !important;
	min-height: 42px;
	cursor: not-allowed !important;
	padding-top: 5px;
	padding-left: 5px;
}
