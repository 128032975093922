.productType {
	font-size: 12px;
}

.scrollableContainer {
	overflow-y: auto;
	max-height: 400px;
	margin-top: 22px;
}

.toggleButton {
	display: inline;
	margin-bottom: 10px;
	margin-top: -4px;
}

.toggleButtonContainer {
	padding: 4px 6px 7px 16px;
}

.toggleButtonContainer :global(.slds-checkbox_faux:after) {
	width: 0.7rem;
	height: 0.7rem;
	border: none !important;
	background-color: gray;
}

.toggleButtonContainer :global(.slds-checkbox_faux:before) {
	left: 1rem !important;
	width: 0.7rem !important;
	height: 0.7rem !important;
	background-color: #f26122 !important;
}

.toggleButtonContainer:global(.slds-checkbox_toggle [type='checkbox']:checked) {
	display: none;
}

.toggleButtonContainer :global(.slds-checkbox_faux) {
	width: 30px;
	height: 15px;
}

.checkboxColor:before {
	background-color: #f26122 !important;
}

.checkboxColor:global(
		.slds-checkbox_toggle
			[type='checkbox']:checked
			+ .slds-checkbox_faux_container
			.slds-checkbox_faux
	) {
	background-color: #f26122 !important;
}

/* Override Salesforce toggle button color when checked */
.toggleButtonContainer
	:global(
		.slds-checkbox_toggle [type='checkbox']:checked + .slds-checkbox--faux
	),
.toggleButtonContainer
	:global(
		.slds-checkbox_toggle
			[type='checkbox']:checked
			+ .slds-checkbox--faux_container
			.slds-checkbox--faux
	),
.toggleButtonContainer
	:global(
		.slds-checkbox_toggle [type='checkbox']:checked + .slds-checkbox_faux
	),
.toggleButtonContainer
	:global(
		.slds-checkbox_toggle
			[type='checkbox']:checked
			+ .slds-checkbox_faux_container
			.slds-checkbox_faux
	),
.toggleButtonContainer
	:global(
		.slds-checkbox_toggle [type='checkbox']:checked ~ .slds-checkbox--faux
	),
.toggleButtonContainer
	:global(
		.slds-checkbox_toggle [type='checkbox']:checked ~ .slds-checkbox_faux
	) {
	border-color: #f1bba3 !important;
	background-color: #f1bba3 !important;
}

.modalContainer {
	width: 400px !important;
}

.customHeading {
	font-size: 16px;
	font-weight: bold;
}

.confirmationModalBtn {
	padding: 19px 6px !important;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.bodyHeader {
	padding: 15px;
	text-align: center;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	font-family: Poppins-Regular;
}
.bodyContent {
	padding: 0 15px 15px 15px;
}

.remarks {
	resize: none;
	height: 100px;
}

.bodyContent :global(.slds-form-element__label) {
	font-size: 11px !important;
	font-family: Poppins-Regular;
	font-weight: 400 !important;
}

.remarksSubLabel {
	font-size: 11px;
	font-family: Poppins-Regular;
	color: #8b9095 !important;
}

.productTypeLink:hover {
	color: #f26122;
	font-weight: bold;
}

.displayRow {
	content: '';
	display: table;
	clear: both;
	width: 100%;
	box-sizing: border-box;
}

.displayColumn {
	float: left;
	box-sizing: border-box;
}

.displayColumn:first-child {
	width: 90%;
	margin-top: 4px;
}

.displayColumn:last-child {
	width: 10%;
}
