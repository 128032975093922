.container {
	font-size: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
	height: 50px;
	background-color: #fff;
	color: #3b3b3b;
	min-width: 300px;
	align-items: center;
	padding: 6px 16px;
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
		0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
}

.customLoader {
	width: 25px;
	height: 6px;
	background: radial-gradient(circle closest-side, #ffd6a5 90%, #0000) 0% 50%,
		radial-gradient(circle closest-side, #f3aa60 90%, #0000) 50% 50%,
		radial-gradient(circle closest-side, #f24c3d 90%, #0000) 100% 50%;
	background-size: calc(100% / 3) 100%;
	background-repeat: no-repeat;
	animation: d7 1s infinite linear;
}

@keyframes d7 {
	33% {
		background-size: calc(100% / 3) 0%, calc(100% / 3) 100%, calc(100% / 3) 100%;
	}
	50% {
		background-size: calc(100% / 3) 100%, calc(100% / 3) 0%, calc(100% / 3) 100%;
	}
	66% {
		background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0%;
	}
}

.alertCloseIcon:hover {
	cursor: pointer;
}

.alertCloseIcon:hover path {
	fill: #707070 !important;
}
