.container {
	font-family: var(--common-font);
}

.header .leftPanel,
.header .rightPanel {
	flex-direction: column;
}

.footer .leftPanel,
.footer .rightPanel {
	flex-direction: row;
}

.leftPanel {
	display: flex;
	align-items: center;
	justify-content: left;
}

.rightPanel {
	display: flex;
	align-items: flex-end;
	justify-content: right;
}

.header {
	margin-bottom: 2vh;
	display: flex;
	justify-content: space-between;
}

.title {
	text-align: left;
	font-family: var(--common-font-bold);
	font-size: 1.2vw;
	width: 100%;
	margin-bottom: 2vh;
}

.status {
	display: flex;
	align-items: center;
}

.statusLabel {
	font: normal 0.8vw Poppins-Regular;
}

.statusField {
	margin-left: 10px;
	width: 125px;
}

.edit {
	margin-bottom: 2vh;
}

.linkBtn {
	border: none !important;
	color: orange !important;
	font: bold 12px Poppins-Regular !important;
	background: none !important;
}

.edit svg {
	margin-right: 0.5vw;
	fill: var(--orange);
	width: 1vmax;
	height: 1vmax;
	border-width: 0px;
	color: blue;
}

.edit:disabled svg {
	fill: #c3c3c3;
}

.otpHeader {
	color: #2196f3;
}

.otpModalExpirationText {
	/* text-align: left;
		align-items: left;
		justify-content: left;
		margin-top: 0.5vh; 
		margin-left: -7px;*/
	width: 100%;
	font-size: 12px;
	text-align: left;
	margin-top: -15px;
	margin-bottom: 15px;
	max-width: 300px;
}

.regenTimerText {
	width: 100%;
	font-size: 12px;
	text-align: center;
	color: gray;
}

.expirationTimeStyle {
	color: #2196f3 !important;
}

.expirationAlign {
	padding-top: 0%;
	margin-left: -7px;
}

.otpModalErrorMessage {
	color: red;
}

.otpModalSuccessMessage {
	color: green;
}

.otpModalSubtext {
	display: block;
	font-size: 8.5pt;
	margin-bottom: 10px;
	text-align: center;
}

.clockIcon {
	fill: green;
	width: 1.3vw;
	margin-top: 0vh;
}

.body {
	height: 500px;
}

.hr {
	/* margin-top: 10px !important;
	margin-bottom: 10px !important; */
}

.footer {
	margin-top: 2vh;
	display: flex;
	justify-content: space-between;
}

.btn {
	padding: 19px 6px !important;
	min-width: 120px !important;
	font-size: 13px !important;
	word-wrap: break-word;
	border-radius: 4px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
}

.dates {
	text-align: right;
	margin-right: 16px;
}

.dateText {
	display: block;
	color: #8b9095;
	font-size: 0.8vw;
}

.submitRemarks {
	resize: none;
	height: 100px;
}

.submitRemarksSubLabel {
	font-size: 11px;
	font-family: Poppins-Regular;
	color: #8b9095 !important;
}

.updateStatus {
	justify-content: left;
}

.updateStatusBody1 {
	text-align: center;
	margin-bottom: 20px;
}

.updateStatusBody2 {
	text-align: left;
}

.updateStatusRemarks {
	resize: none;
	height: 100px;
}

.updateStatusSubLabel {
	font-size: 11px;
	font-family: Poppins-Regular;
	color: #8b9095 !important;
	text-align: left;
}

.approveHeading {
	top: 250px;
	left: 602px;
	font-size: 14px;
	font-weight: 600;
	font-stretch: condensed;
}

.sendButton {
	background-color: white;
	border: 2px solid #f26122;
	color: #f26122;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 5px;
	padding: 6px 12px;
	/* 
	font-size: 16px; */
}

.sendButton:disabled {
	background-color: gray;
	color: white;
	border: 2px solid gray;
}

.sendButton:disabled:hover {
	background-color: gray;
	color: white;
	border: 2px solid gray;
}

.sendButton:hover {
	background-color: #f26122;
	color: white;
}

.sendButton:focus {
	outline: none;
}

.otpModalBtn {
	margin-bottom: 10px;
	padding: 29px 16px;
	width: 320px;
	height: 240px;
	font-size: 13px !important;
}
.channelCredentialsTableContainer :global(.slds-card) {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.channelCeretificateTableContainer :global(.slds-card) {
	padding: 0.4vmax;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.channelAuditTrailTableContainer :global(.slds-card) {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.channelCredentialsBtn {
	padding-right: 0 !important;
}

.actionHeader {
	text-align: center;
	width: 100%;
}

.successBody {
	font-size: 0.8vmax;
	margin-top: 12px;
}

.errorHeader {
	font-family: var(--common-font);
	font-size: clamp(10px, 1vw, 18px);
	margin-top: 0.7vh;
	margin-bottom: 2.5vh;
}

.successBodyAdjust {
	font-size: 0.8vmax;
	margin-top: 3vh;
	margin-bottom: 1vh;
	padding: 0 0.4vw;
}

.successModalBtn {
	margin-bottom: 1vh;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}
