.container {
	margin-top: 3vh;
	border: 2px solid var(--orange);
	padding: 0.8vmax;
	padding-bottom: 0.5vmax;
	border-radius: 10px;
	font-family: var(--common-font);
	font-size: 0.7vw;
}

.filterTitle {
	letter-spacing: 0.43px;
	color: #3b3b3b;
}

.inputContainer > div {
	/* padding-right: 15px; */
	padding: 0rem 0.1rem;
}

.inputContainer div.error {
	color: #d93025;
	font: normal normal 9px/16px Poppins-Regular;
}

.inputContainer label {
	font: normal normal 12px/18px Poppins-Regular;
	letter-spacing: 0px;
	color: #3b3b3b;
}

.filterButton {
	width: 9vw;
	min-height: 28px !important;
	float: right;
}

.outlineButton {
	background-color: #fff !important;
	color: #f26122 !important;
	border: 1px solid #f26122 !important;
	width: 9vw;
	min-height: 28px !important;
	float: right;
}

.outlineButton:disabled {
	background-color: #fff !important;
	color: #f5a786 !important;
	border: 1px solid #f5a786 !important;
}

.outlineButton:hover:enabled {
	background-color: #f26122 !important;
	border: 1px solid #f26122 !important;
	color: #fff !important;
}

.filterButton:focus {
	background-color: #f26122;
	border: 1px solid #f26122;
}

.inputContainer div.errorDtp > div > input {
	border: 1px solid #d93025;
	color: #000000;
}

.errorInput {
	border: 1px solid #d93025;
}

label {
	font-size: var(--input-label-font-size) !important;
}

.dummySpace {
	font-size: 12px;
	line-height: 18px;
	display: block;
}
