.iconBtn {
	width: 1vw;
	height: 1vw;
}

.viewIcon,
.viewIcon:focus {
	color: var(--orange);
}
.viewIcon:hover {
	color: var(--darkerOrange);
}

.tooltip {
	background: #f1f1f1 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 2px;
	color: #3b3b3b !important;
	font: normal normal 10px/16px Poppins-Light;
}

.tooltip > div {
	color: #3b3b3b !important;
	font: normal normal 10px/16px Poppins-Light;
}

.labelActive {
	color: #14bb02;
}

.labelInactive {
	color: #c84031;
}

.errorBodyContent {
	padding-left: 15px;
	padding-right: 15px;
}

.errorHeader {
	font-weight: bold;
	margin-bottom: 10px;
	font: normal normal 1.2vmax Poppins-Light;
}

.errorBody {
	font: normal normal 10px/16px Poppins-Light;
}

.errorFooter {
	margin-top: 0;
	margin-bottom: 30px;
	font: normal normal 10px/16px Poppins-Light;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}
.cardTitle {
	color: var(--orange);
	font-size: 1.2vw;
	font-family: var(--common-font);
}

.divCardContainer {
	margin-top: 3vh;
	border: 2px solid var(--orange);
	padding: 1.5vmax;
	padding-bottom: 0.5vmax;
	border-radius: 10px;
	font-family: var(--common-font);
	font-size: 0.7vw;
}
.container {
	margin-top: 3vh;
	border: 2px solid var(--orange);
	padding: 0.8vmax;
	padding-bottom: 0.5vmax;
	border-radius: 10px;
	font-family: var(--common-font);
	font-size: 0.7vw;
}

.filterTitle {
	letter-spacing: 0.43px;
	color: #3b3b3b;
}

.inputContainer > div {
	padding-right: 5px;
}

.inputContainer div.error {
	color: #d93025;
	font: normal normal 9px/16px Poppins-Regular;
}

.inputContainer label {
	font: normal normal 12px/18px Poppins-Regular;
	letter-spacing: 0px;
	color: #3b3b3b;
}

.filterButton {
	width: 45%;
	height: 100%;
	float: inherit;
	margin-right: 0%;
}
.clearButton {
	width: 45%;
	height: 100%;
	margin-right: 5px;
	/* sfloat: inherit; */
	background-color: white !important;
	border: 1px solid orange !important;
	color: orange !important;
}

.filterButton:hover {
	background-color: #f26122;
	border: 1px solid #f26122;
}

.clearButton:hover {
	background-color: #fcded2 !important;
	border: 1px solid orange !important;
}

.inputContainer div.errorDtp > div > input {
	border: 1px solid #d93025;
	color: #000000;
}

.errorInput {
	border: 1px solid #d93025;
}

label {
	font-size: var(--input-label-font-size) !important;
}

.innerButtons {
	padding-top: 2.2em;
}

.iconBtn {
	width: 1vw;
	height: 1vw;
}

.rowBreak {
	word-break: break-all;
}

.errorBody {
	font-size: 0.8vmax;
}

.errorFooter {
	margin-top: 1em;
	font-size: 0.7vw;
	color: #3e3f42;
}

.field {
	align-items: center;
	font-size: 10.5px !important;
}

.selectField {
	height: 1.5vmax !important;
}

.selectField :global(.slds-button) {
	font-size: 0.7vmax !important;
	color: #3b3b3b;
	opacity: 1;
}

.textField :global(.slds-input) {
	height: 1.7vmax !important;
	color: #3b3b3b;
	opacity: 1;
	font-size: 0.7vmax !important;
	padding-left: 0.7vmax !important;
}

.label {
	margin-top: 0.2vw !important;
	margin-bottom: 0.1vw !important;
	font-size: 0.7vmax !important;
	color: #3b3b3b;
	opacity: 1;
}

.label
	> :global(div.lookupPrefix__control)
	> :global(div.lookupPrefix__indicators)
	> :global(span.lookupPrefix__indicator-separator) {
	margin: 0vw !important;
	font-size: 0.7vmax !important;
	color: #3b3b3b;
	opacity: 1;
}

.filters {
	padding: 1vw 0.75vw 0.75vw !important;
}

.filters > div[class]:last-child {
	padding: 0.5vw !important;
}

.filters > div[class]:last-child > div[class]:last-child {
	padding-bottom: 0 !important;
}

.filters > div[class]:last-child > div[class]:first-child {
	font-size: 0.9vw !important;
}

.filters > div[class] > div[class] > div:first-child {
	font-size: 1.4vw !important;
}

.filters > div[class]:last-child {
	margin-top: 0.5vw !important;
}

.tableContainer {
	padding: 1vw 0.75vw 1vw;
}

.tableContainer > [role='table'] > [role='rowgroup'] > [role='row'] div {
	padding-bottom: 4px !important;
}

.lookupTextField
	:global(.lookupPrefix__control)
	:global(.lookupPrefix__value-container) {
	padding-right: 2px !important;
	padding-left: 5px !important;
	height: 1.63vmax !important;
	font-size: 0.68vmax !important;
	color: #3b3b3b;
	opacity: 1;
	max-width: 150px;
}

.lookupTextField
	:global(.lookupPrefix__control)
	:global(.lookupPrefix__indicators)
	:global(.lookupPrefix__indicator) {
	padding: 0 5px 0 5px !important;
	margin: 0 0 0 5px !important;
}

.lookupTextLabel {
	margin-top: 0.25vmax !important;
	margin-bottom: 0 !important;
	font-size: 0.7vmax !important;
	color: #3b3b3b;
	opacity: 1;
}

.buttons {
	margin-top: 1.35vmax !important;
	padding: 0.5vw !important;
	font-size: 0.7vmax !important;
}

.dateField :global(.slds-input) {
	margin-top: 0 !important;
	height: 1.5vmax !important;
	font-size: 0.7vmax !important;
}

.dateField :global(.slds-form-element__label) {
	margin-bottom: 0.01vmax !important;
	padding-top: 0.1vmax !important;
}

.dateField :global(.slds-form-element__label) label {
	font-size: 0.7vmax !important;
}

@media screen and (max-width: 1330px) {
	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__value-container),
	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__indicators) {
		height: 1.8vmax !important;
	}

	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__value-container)
		:global(.lookupPrefix__placeholder) {
		margin: 0 !important;
		padding: 0 !important;
	}

	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__value-container) {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		font-size: 0.61vmax !important;
	}

	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__indicators)
		:global(.lookupPrefix__indicator) {
		padding: 0 5px 0 2px !important;
		margin: 0 !important;
	}

	.dateField :global(.slds-form-element__control) :global(.slds-input) {
		height: 1.7vmax !important;
		font-size: 0.7vmax !important;
		padding-right: 0 !important;
	}

	.textField :global(.slds-input) {
		height: 1.93vmax !important;
		color: #3b3b3b;
		opacity: 1;
		font-size: 0.7vmax !important;
	}

	.selectField :global(.slds-button) {
		font-size: 0.68vmax !important;
	}
}

@media screen and (min-width: 200px) and (max-width: 3000px) {
	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__value-container),
	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__indicators) {
		height: 1.8vmax !important;
	}

	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__value-container)
		:global(.lookupPrefix__placeholder) {
		margin: 0 !important;
		padding: 0 !important;
	}

	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__value-container) {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		font-size: 0.61vmax !important;
	}

	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__indicators)
		:global(.lookupPrefix__indicator) {
		padding: 0 5px 0 2px !important;
		margin: 0 !important;
	}

	.lookupTextLabel {
		margin-top: 0.25vmax !important;
		margin-bottom: 0.02vmax !important;
	}

	.textField :global(.slds-input) {
		height: 1.93vmax !important;
		color: #3b3b3b;
		opacity: 1;
		font-size: 0.7vmax !important;
		padding-left: 0.7vmax !important;
	}
}
