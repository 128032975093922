.container {
	padding-top: 1vmax;
}

.container > * {
	margin-bottom: 2vh;
}

.memoLabelContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding-left: 20px;
	margin-bottom: 10px;
}

.memoLabel {
	font-family: var(--common-font-bold);
	font-size: 1.05vw;
	margin-bottom: 1.85vh;
}

.memoSubtext {
	color: #8b9095;
	font-size: 0.6vw;
}

.memoSubtextLabel {
	font-size: 0.8vw;
}

.memoSubtextValue {
	color: black;
	font-weight: bold;
	margin-left: 0.5vw;
}

.memoUploadBtn {
	width: 100%;
}

.toolTip > div {
	background: #f1f1f1 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 2px;
	color: #3b3b3b !important;
	font: normal normal 10px/16px Poppins-Light;
}

.toolTip > div > div {
	color: #3b3b3b !important;
	font: normal normal 10px/16px Poppins-Light;
}
