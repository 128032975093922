.thead {
	background: #0176c0 0% 0% no-repeat padding-box;
	border-radius: 10px 10px 0px 0px;
	padding: 8px 20px 8px 20px;
}

.th {
	background: inherit !important;
	font: normal normal 600 14px/16px Wavehaus-SemiBold;
	color: #ffffff;
}

.icons {
	width: 0.675rem;
	height: 0.675rem;
}

.iconDown {
	position: relative;
	top: -4px;
}

.iconUp {
	position: relative;
	bottom: -4px;
}

.tr {
	font: normal normal 14px/16px Poppins-Regular;
	color: #3b3b3b;
	padding: 10px 25px;
}

.tr:nth-child(even) {
	background: #f3faff 0% 0% no-repeat padding-box;
	border-bottom: 1px solid #dadfe2;
	border-top: 1px solid #dadfe2;
}

.paginationContainer {
	padding: 10px 25px 38px 25px;
	overflow-x: auto;
	overflow-y: hidden;
}

.paginationInfoContainer {
	display: flex;
	align-items: center;
}

.paginationInfo {
	vertical-align: middle;
	font: normal normal 12px/18px Poppins-Medium;
	color: #6d6d6d;
	display: flex;
	align-items: center;
	width: 150px;
}

.paginationWithoutFrom {
	color: red;
}

.pagination {
	height: 43px;
	font: normal normal 12px/18px Poppins-Regular;
}

.pagination button,
.pagination button:hover,
.pagination button:active,
.pagination button:focus {
	color: #f26122;
}

.pagination button:disabled {
	color: #00000099;
}

.limitContainer > div > div > ul {
	max-height: 4.5rem !important;
}

.pageActive {
	background-color: var(--orange) !important;
	color: white !important;
}
