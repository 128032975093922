.container {
	font-family: var(--common-font);
	max-width: 59vw;
}

.title {
	font-family: var(--common-font-bold);
	font-size: 1.2vw;
}

.header {
	margin-bottom: 3.1vh;
	padding-top: 1vmax;
	padding-left: 0.5vmax;
	padding-right: 0.5vmax;
}

.row {
	margin-bottom: 2vh;
}

.confirmationHeader {
	font-size: 18px;
}

.subtext {
	margin-top: 2vh;
	font-size: 0.7vw;
}

.successBtn {
	padding: 19px 6px !important;
	min-width: 120px !important;
	font-size: 13px !important;
	height: 40px;
	border-radius: 4px;
}

.containerWidth {
	align-self: center;
	top: 0vh;
	max-width: 420px;
	width: 80%;
	text-align: center;
}

.status {
	display: flex;
	align-items: center;
	margin-top: -3vh;
	margin-bottom: 3vh;
}

.statusLabel {
	font: normal 0.8vw Poppins-Regular;
}

.statusField {
	margin-left: 10px;
	width: 125px;
}

.rightPanelText {
	display: flex;
	align-items: flex-end;
	justify-content: right;
	margin-top: -12vh;
}

.dates {
	text-align: right;
	margin-right: 16px;
}

.dateText {
	display: block;
	color: #8b9095;
	font-size: 0.8vw;
}

.contentSpace {
	margin-top: 6vh;
}

.displayColumn {
	float: right;
	/* width: 50%; */
	/* padding: 10px; */
	/* border: solid 1px #199068;	 */
	margin-left: 5px;
}

.displayRow {
	content: '';
	display: table;
	clear: both;
	/* border: solid 1px #e00404; */
	width: 100%;
}
