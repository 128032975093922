.searchBar {
	width: 100%;
}

.success {
	font-size: 18px;
	margin-top: 1vh;
	margin-bottom: 2vh;
	font-weight: bold !important;
}

.subtext {
	font-size: 18px;
	margin-bottom: 8px;
	font-weight: bold !important;
}

.downloadButton {
	height: 100%;
}

.statusActive {
	color: #14bb02;
}

.statusDeactivated {
	color: #8b9095;
}

.statusInactivated {
	color: #000000;
}

.innerButtons {
	padding-top: 1.9em;
}

.iconBtn {
	width: 1vw;
	height: 1vw;
}

.checkIcon {
	fill: #14bb02;
}

.crossIcon,
.crossIcon:focus {
	color: var(--orange);
}

.crossIcon:hover {
	color: var(--darkerOrange);
}

.errorBody {
	font-size: 0.8vmax;
}

.errorFooter {
	margin-top: 1em;
	font-size: 0.7vw;
	color: #3e3f42;
}

.filters > div,
.innerButtons > div {
	padding-left: 0.2em;
	padding-right: 0.2em;
}

.serviceTypeContainer {
	min-width: 20%;
}

.datePickerContainer {
	min-width: 15%;
}

.clearFilterBtnContainer {
	min-width: 60%;
}

.retryBtn {
	max-width: 50%;
	font-size: 2px;
}

.actionCell button {
	width: min(1vmax, 20px);
	height: min(1vmax, 20px);
}

.actionHeader {
	text-align: center;
	width: 100%;
}

.walletId {
	word-break: break-all;
}

.walletTypeDropdown span {
	white-space: normal;
	font-size: 0.6vw !important;
}

.toggleButtonContainer {
	padding: 5px 0px 5px 15px;
}

.toggleButtonContainer :global(.slds-checkbox_faux:after) {
	width: 0.7rem;
	height: 0.7rem;
	border: none !important;
}

.toggleButtonContainer :global(.slds-checkbox_faux:before) {
	left: 1rem !important;
	width: 0.7rem !important;
	height: 0.7rem !important;
}

.toggleButtonContainer:global(.slds-checkbox_toggle [type='checkbox']:checked) {
	display: none;
}

.toggleButtonContainer :global(.slds-checkbox_faux) {
	width: 30px;
	height: 15px;
}

.toggleButtonContainer:global(
		.slds-checkbox_toggle
			[type='checkbox'][disabled]
			+ .slds-checkbox_faux_container
			.slds-checkbox_faux:after
	) {
	background-color: #cbcbcb;
}

.bodyText {
	font: normal normal normal 12px/18px Poppins-Regular;
	letter-spacing: 0px;
}

.bodyHeader {
	font: normal normal 600 16px/19px Wavehaus-SemiBold;
	letter-spacing: 0px;
	color: #1e1e1e;
}
