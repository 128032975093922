.customModal {
	font-family: var(--common-font);
	overflow: hidden;
	width: 1071px !important;
	max-height: 724px;
}
.customModalHeader {
	padding: 1vmax;
}

.header {
	display: flex;
	margin-bottom: 20px;
	margin-top: 10px;
	justify-content: left;
	align-items: center;
}

.modalFullName {
	font-size: 22px;
	text-align: left;
	letter-spacing: 0px;
	color: #1e1e1e;
}

.errorBodyContent {
	padding-left: 15px;
	padding-right: 15px;
}

.errorHeader {
	font-weight: bold;
	margin-bottom: 10px;
	font: normal normal 1.2vmax Poppins-Light;
}

.errorBody {
	font: normal normal 10px/16px Poppins-Light;
}

.errorFooter {
	margin-top: 0;
	margin-bottom: 30px;
	font: normal normal 10px/16px Poppins-Light;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.statusVerifiedText {
	color: #14bb02;
	font-style: italic;
	font-size: 13px;
}

.statusUnverifiedText {
	color: #c84031;
	font-style: italic;
	font-size: 13px;
}
