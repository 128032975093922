.fontPoppins {
    font-family: Poppins-Regular
}

.fontPoppinsLight {
    font-family: Poppins-Light
}

.imgHardLight {
    width: 55%;
    mix-blend-mode: hard-light;
}
