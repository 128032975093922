.addButton svg {
	margin-right: 0.5vw;
	fill: var(--blue);
	width: 1vmax;
	height: 1vmax;
}

.addButton:disabled svg {
	fill: #c3c3c3;
}

.contactDetailsErrorCloseBtn {
	min-width: 40%;
}

.confirmTextName {
	font-size: 12px;
	font-weight: bold;
	text-align: end;
}
.confirmPartnerContactDetails {
	font-weight: bold;
	font-size: 13px;
}

.firstEntry {
	padding-top: 0;
}

.confirmationModalBodyHeader {
	padding: 10px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	font-family: 'wavehaus-95-semibold', sans-serif;
}

.successModalText {
	font-size: 18px;
	margin-top: 10px;
	font-family: Wavehaus-Semibold;
}

.successModalBtnDone {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}