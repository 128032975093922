.fontPoppins {
    font-family: Poppins-Regular
}

.leftPane {
    height: 500px;
    background-color: #FFFFFF;
    border-radius: 0px 4px 4px 0px;
    box-shadow: 0px 3px 10px #00000029;
}

.rightPane {
    height: 500px;
    background-color: #0076C0;
    border-radius: 0px 4px 4px 0px;
    box-shadow: 0px 3px 10px #00000029;
}

a, a:hover, a:active, a:focus  {
    text-decoration: none;
    color: #000000;
}
