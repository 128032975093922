.alertContainer {
	display: flex;
	width: 100%;
	background-color: #e5e78b;
	min-height: 25px;
	border: 1px solid #cccf19;
	border-radius: 3px;
	padding: 9px 17px 9px 17px;
	margin-bottom: 10px;
	font-family: Poppins-Regular;
}

.alertCancel {
	margin-right: 16px;
}

.alertConfirm {
	margin-right: 65px;
}

.alertCloseIcon:hover {
	cursor: pointer;
}

.alertCloseIcon:hover path {
	fill: #707070 !important;
}

.alertCancel:hover,
.alertConfirm:hover {
	text-decoration: underline;
	font-weight: bold;
}
