.tabs {
	/* overflow-y: hidden; */
	height: 100%;
	padding-bottom: 20px;
}
.tabs :global(.slds-tabs_default__link) {
	text-decoration: none !important;
}

.tabs :global(.slds-tabs_default__item.slds-is-active:after),
.tabs :global(.slds-tabs_default__item:hover:after) {
	background-color: var(--orange);
}

.tabs :global(.slds-disabled) {
	pointer-events: none;
}

.tabs :global(.slds-tabs_default__item) {
	height: 4.5vh;
	padding-left: 0.5vw;
	padding-right: 0.5vw;
	font-size: 0.8vw;
	padding-bottom: 30px;
}

.tabs :global(.slds-tabs_default__content) {
	height: 100%;
}

.tabDisabled {
	color: gray;
	pointer-events: none;
	cursor: not-allowed !important;
}

.tabCheck {
	margin-left: 0.5vw;
	color: #28a745;
}

.content {
	/* overflow-x: hidden; */
	overflow-y: auto;
	height: 100%;
}

.withPreHeader {
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
	width: 100%;
	padding: 10px 16px;
	overflow-x: auto;
}

.status {
	display: flex;
	align-items: center;
}

.statusLabel {
	font: normal 0.8vw Poppins-Regular;
}

.statusField {
	margin-left: 10px;
	width: 125px;
}

.tabLabel {
	width: 170px;
	text-align: center;
	font-size: 10px;
}
@media only screen and (max-width: 1366px) {
	.tabLabel {
		width: 170px;
		text-align: center;
		font-size: 10px;
	}
}
@media only screen and (min-width: 1920px) {
	.tabLabel {
		width: 213px;
		text-align: center;
		font-size: 10px;
	}
}
