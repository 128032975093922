.productContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
}

.productContainer:first-child {
	margin-left: 0 !important;
}

.emptyContainer::before {
	content: 'No results found';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.paperContainer {
	padding: 0.3vmax 2vmax 2vmax 2vmax;
	border-radius: 15px;
	min-height: 60vh;
}

.actionContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.action {
	color: var(--orange);
	font-weight: bold;
	text-decoration: underline;
	cursor: pointer;
	display: inline-block;
}

.errorBodyContent {
	padding-left: 15px;
	padding-right: 15px;
}

.errorHeader {
	font-weight: bold;
	margin-bottom: 10px;
	font: normal normal 1.2vmax Poppins-Light;
}

.errorBody {
	font: normal normal 10px/16px Poppins-Light;
}

.errorFooter {
	margin-top: 0;
	margin-bottom: 30px;
	font: normal normal 10px/16px Poppins-Light;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.divider {
	margin: 0 !important;
}

.filters > div[class] > div[class] > div:first-child {
	font-size: 1.4vw !important;
}

.filters {
	padding: 1.2vw 0 0.8vw !important;
}

.bodyHeaderEmphasis {
	font-weight: bold;
}
