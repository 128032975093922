.container {
	font-family: var(--common-font);
	border: 2px solid #f26122;
	border-radius: 50px;
	padding: 17px 23px 5px 23px;
	margin-bottom: 15px;
	background-color: #fff;
}

.inputContainer > div {
	padding-right: 15px;
}

.container label {
	font: normal normal 12px/18px Poppins-Regular;
	letter-spacing: 0px;
	color: #3b3b3b;
}

.container h1 {
	font: normal normal 22px/26px Wavehaus-SemiBold;
	letter-spacing: 0px;
	color: #3b3b3b;
}

.buttonDiv {
	padding: 20px;
	text-align: center;
}

.textArea > div > textarea {
	background: #ffffff 0% 0% no-repeat padding-box !important;
	border: 1px solid #dadce0 !important;
	border-radius: 4px;
	height: 131px;
}

.tabContainer {
	display: flex;
	border-bottom: 2px solid #0179bd;
}

.cTab {
	width: 100px;
	height: 40px;
	display: flex;
	margin-right: 12px;
	justify-content: end;
	flex-direction: column;
	background-color: #e8f6fc;
	border-radius: 10px 10px 0 0;

	cursor: pointer;
}

.cTab span {
	font-size: 14px;
	text-align: center;
	margin-bottom: 4px;
}

.active {
	color: #ffffff;
	background-color: #0179bd;
}

.datatable thead tr {
	height: 50px;
}

.datatable thead tr th:first-child {
	padding-left: 0;
}

.datatable thead tr th {
	font-family: 'Poppins-Medium';
	vertical-align: bottom;
	background-color: transparent;
}

.datatable tr td {
	color: #3b3b3b;
	padding: 10px 8px;
	border-top: 1px solid #efefef;
	vertical-align: top;
	font: normal normal 14px/16px Poppins-Regular;
}

.datatable tbody tr td[data-label='Data'],
.datatable tbody tr td[data-label='Old Values'],
.datatable tbody tr td[data-label='New Values'] {
	word-break: break-word;
	white-space: normal;
}

.datatable tr td:first-child {
	padding-left: 0 !important;
}

.datatable tbody tr td[data-label='Data'],
.datatable tbody tr td[data-label='Old Values'],
.datatable tbody tr td[data-label='New Values'] {
	min-width: 300px;
	max-width: 500px;
}

.datatable tr td:last {
	padding: 10px 24px 10px 8px;
}

.datatable tr:nth-child(even) td {
	background-color: #f3faff !important;
}

.statusText {
	color: white;
}

.badgeSuccess {
	background-color: rgb(85, 235, 85);
}

.vrText {
	font: normal normal 12px/18px Poppins-Regular;
}

.vrid {
	text-decoration: underline;
	color: rgb(60, 144, 223);
	font-weight: 600;
	font-size: medium;
	cursor: pointer;
	word-break: break-all;
}

.pill_pending {
	background: #f26122;
}
.pill_approved {
	background: #6fcf6c;
}
.pill_rejected {
	background: #ff0000;
}
.pill_expired {
	background: #b3b7bb;
}

.voidStatusContainer {
	text-align: right;
	margin-right: 1.5vmax;
}

.voidStatusWrap {
	text-align: left;
	display: inline-block;
}

.voidStatusWrap label {
	margin-bottom: 1.1vh;
}

.voidStatusWrap pre {
	font-family: var(--common-font-bold) !important;
	word-break: break-all;
}
