.innerButtons {
	padding-top: 2.2em;
}

.iconBtn {
	width: 1vw;
	height: 1vw;
}

.rowBreak {
	word-break: break-all;
}

.downloadDropdown {
	opacity: 0;
	pointer-events: none;
	position: fixed;
	right: 2%;
	top: 12%;
}

.downloadContainer {
	position: relative;
}

.errorHeader {
	font-weight: bold;
	font-size: 1.2vmax;
	margin-bottom: 10px;
}

.errorBody {
	font-size: 0.8vmax;
}

.errorFooter {
	margin-top: 0;
	font-size: 0.8vmax;
	margin-bottom: 30px;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.successHeader {
	font-weight: bold;
	font-size: 1.2vmax;
	margin-bottom: 10px;
}

.successBody {
	font-size: 0.8vmax;
	margin-bottom: 30px;
}

.successModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.filters > div,
.innerButtons > div {
	padding-left: 0.2em;
	padding-right: 0.2em;
}

.statusReview,
.iconOrange {
	color: var(--orange);
}

.centerAlign {
	text-align: center;
	width: 100%;
}

.toolTip {
	width: 100%;
}

.toolTip > div {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.toolTip > div > div {
	color: #3B3B3B !important;
	font: normal normal 10px/16px Poppins-Light;
}
