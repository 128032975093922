.select {
	font-family: var(--common-font-light);
	font-size: var(--input-label-font-size);
	width: 100%;
}

.select > button {
	height: 100%;
	min-height: var(--input-min-height);
	color: #080707 !important;
	justify-content: space-between;
	padding-right: 0.5vw;
	padding-left: 0.5vw;
	line-height: 0.1vh;
}

.select > button svg {
	width: 0.7vmax;
	height: 0.7vmax;
}

.select.empty > button {
	color: gray !important;
}

.select > div {
	min-width: 100% !important;
}

.select > div > ul > li > a {
	font-family: var(--common-font);
	font-size: var(--input-label-font-size);
	letter-spacing: 0.11px;
}

.select > div > ul > li > a:hover {
	color: var(--orange);
	background-color: #fff;
}

.selectItem {
	font: normal normal 12px/18px Poppins-Regular;
}

.selectItem:hover > a,
.selectItemActive > a {
	color: var(--orange);
	z-index: 10000000;
}
