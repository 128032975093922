.headerText{
    text-align: center;
    font: normal normal 600 18px/21px Wavehaus;
    letter-spacing: 0px;
    color: #1E1E1E;
    opacity: 1;
}

.bodyText{
    text-align: center;
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0px;
    opacity: 1;
}

.buttonDiv{
    padding: 20px;
    text-align: center;
}

.closeIcon{
    text-align: center;
    padding-top: 20px;
}

