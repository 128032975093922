.select {
    font-family: var(--common-font);
    width: 100%;
}

.select>button {
    height: 100%;
    min-height: var(--input-min-height);
    color: #706e6b !important;
    justify-content: space-between;
    padding: 0.2vw !important;
    padding-left: .5vw !important;
    width: 100%;
    line-height: 3vh;
    font-size: var(--input-label-font-size);
}

.select.loading>button svg {
    animation: spin infinite linear 1000ms;
}

.select>button svg {
    width: 0.7vmax;
    height: 0.7vmax;
}

.select.error>button {
    border: 2px solid #ea001e;
}

.select.empty>button {
    color: #706e6b !important;
}

.select.empty>button:disabled {
    cursor: not-allowed;
}

.select>div {
    min-width: 100% !important;
}

.select>div>ul>li>a {
    font-family: var(--common-font);
    font-size: var(--input-label-font-size);
    letter-spacing: 0.11px;
}

.select>div>ul>li>a:hover {
    color: var(--orange);
    background-color: #fff;
}

.selectItem {
    font-size: var(--input-label-font-size);
    font-family: var(--common-font);
}

.selectItem:hover>a,
.selectItemActive>a {
    color: var(--orange);
    z-index: 10000000;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.helper {
    font-size: var(--input-label-font-size);
}

.dropdownContainer {
    color: #3b3b3b;
    border: #f26122;
}

.dropdownContainer> :checked {
    color: #f26122;
}

.container>div {
    padding-top: 2px;
    margin-bottom: 2px;
}

.selectItem>a>span {
    height: 1.1rem;
    display: flex;
    justify-content: center;
}

.selectItem>a>span>span {
    opacity: 1;
    border-style: solid;
    margin-right: 10px;
    border-radius: 2px;
    border-width: 1.2px;
    border-color: #dadce0;
    height: 17px;
}

.selectItem>a>span>span>svg {
    margin: 0px;
    background-color: #f26122;
    border-radius: 0px;
    /* width: 14px;
	height: 13px; */
    margin-bottom: 6px;
}

.selectItem>a>span>span>svg>use {
    filter: brightness(0) invert(1);
}

.tagInput {
    /* border: 1px solid var(--sds-c-card-color-border, #dddbda); */
    /* padding: 0.2vmax; */
    /* border-radius: 5px; */
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    align-items: center;
    overflow-y: auto;
    min-height: 18px;
}

.tagInput.hasError {
    border: 2px solid #ea001e;
}

.tagInput.empty {
    /* padding: 0.3vmax;
    padding-left: 0.8vw; */
    border: none
}

.tagInput:not(.hasError):focus-within {
    border: 1px solid #0077c0;
    box-shadow: var(--sds-c-input-shadow-focus, 0 0 3px #0176d3);
}

.tagInput.hasError:focus-within {
    box-shadow: #ea001e 0 0 0 1px inset, 0 0 3px #0176d3;
}

.tag {
    font-size: var(--input-label-font-size);
    margin: 0.2vh 0.1vw;
    border: 1px solid #0077c0;
    background: #e6f5fe;
    padding: 0 0.3vmax;
    outline: 0;
    width: auto;
    display: flex;
    min-height: 3vh;
}

.tag:hover {
    background: #cfe6f5;
}

.tag:focus,
.tag:active,
.tag.activeTag {
    border: 0.12vmax solid #037cb4;
    font-weight: 600;
}

.tag a,
.tag a:focus,
.tag a:active {
    border: none;
    outline: 0;
    box-shadow: none;
}

.tag :global(.slds-pill__remove) {
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: #3e3f42;
}

.tag :global(.slds-pill__remove svg) {
    color: #aaa;
    width: 0.7vmax;
}

.tag.error :global(.slds-pill__remove svg) {
    fill: #f81c2c;
}

.error {
    border-color: #f81c2c;
    background-color: #f6727c;
    color: black;
}

.error:hover {
    background-color: #f15663;
}

.inputForm {
    flex: 1;
    min-width: 1vw;
}

.inputForm input[type='text'],
.inputForm input[type='text']:focus {
    height: 3vh;
    min-height: 3vh;
    margin: 0;
    font-size: var(--input-label-font-size);
    width: 100%;
    border: none;
    outline: none;
}

.subtext {
    color: #8b9095;
    font-size: 0.6vw;
}

.labelContainer {
    margin-bottom: 1vh;
}

.label {
    margin-bottom: 0 !important;
}

input[type='text']:disabled {
    cursor: not-allowed;
    background-color: #fff;
}

.helper {
    font-size: var(--input-label-font-size);
}

.fullWidth {
    width: 100%;
}

.disabled {
    border-color: rgb(161, 159, 159);
}

.disabled>a {
    color: rgb(161, 159, 159);
}