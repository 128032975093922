.searchBar {
	width: 100%;
}

.downloadButton {
	height: 100%;
}

.statusApproved {
	color: #14bb02;
}
.statusRejected {
	color: #ff0000;
}
.statusPending {
	color: #ffa500;
}
.statusExpired {
	color: #8b9095;
}

.innerButtons {
	padding-top: 1.9em;
}

.iconBtn {
	width: 1vw;
	height: 1vw;
}

.acceptIcon {
	fill: #14bb02;
}

.checkIcon,
.checkIcon:focus {
	color: #14ab02;
}

.checkIcon:hover {
	color: #148b02 !important;
}

.rejectIcon {
	fill: #ff0000;
}

.crossIcon,
.crossIcon:focus {
	color: var(--orange);
}

.crossIcon:hover {
	color: var(--darkerOrange);
}

.errorBody {
	font-size: 0.8vmax;
}

.errorFooter {
	margin-top: 1em;
	font-size: 0.7vw;
	color: #3e3f42;
}

.filters > div,
.innerButtons > div {
	padding-left: 0.2em;
	padding-right: 0.2em;
}

.serviceTypeContainer {
	min-width: 20%;
}

.datePickerContainer {
	min-width: 15%;
}

.clearFilterBtnContainer {
	min-width: 60%;
}

.retryBtn {
	max-width: 50%;
	font-size: 2px;
}

.actionCell button {
	width: min(1vmax, 20px);
	height: min(1vmax, 20px);
}

.actionHeader {
	text-align: center;
	width: 100%;
}

.voidId {
	word-break: break-all;
}

.successModalContainer {
	margin-top: 85px;
	max-height: calc(276px + 20vh);
	max-width: calc(372px + 20vw);
}

.successHeader {
	font-weight: normal;
	font-size: 1vmax;
}
.successMessage {
	font-size: 1vmax;
}

.addressErrorCloseBtn {
	margin-top: 30px;
	padding: 19px 6px;
	width: 140px;
	height: 42px;
	font-size: 13px !important;
}

.errorModalMessage {
	font-weight: bold;
	font-family: 'Poppins-Medium';
}

.toolTip > div {
	background: #f1f1f1 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 2px;
}

.toolTip > div > div {
	color: #3b3b3b !important;
	font: normal normal 10px/16px Poppins-Light;
}

.voidFilter {
	position: relative;
	top: 2px;
}
