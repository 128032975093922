.smallModal {
	width: 25% !important;
	min-width: 0 !important;
	height: 68% !important;
	font: normal normal 600 12px/18px Poppins-Regular !important;
}

.modal {
	width: 30% !important;
	min-width: 0 !important;
}

.footer {
	background-color: #ffffff;
	padding: 0 10px 0 0;
}

.bodyHeader {
	font: normal normal 600 18px/21px Wavehaus-SemiBold;
	letter-spacing: 0px;
	color: #1e1e1e;
}

.bodyText {
	font: normal normal normal 12px/18px Poppins-Regular;
	letter-spacing: 0px;
}
