:global(*) {
	font-family: var(--common-font);
}

.modal {
	font-family: var(--common-font);
	overflow-y: hidden;
}

.body {
	padding: 1vmax;
	height: 400px;
}

.headerContainer {
	border: none;
	padding-bottom: 0;
}

.header {
	overflow: auto;
	overflow-y: hidden;
	padding-top: 1vmax;
	padding-left: 0.5vmax;
	padding-right: 0.5vmax;
}

.partnerStatusContainer {
	padding-left: 16px;
	font-size: 0.8vw;
	width: 200px;
}

.partnerStatusField {
	padding-left: 15px;
	width: auto;
	display: inline-block;
}

.partnerStatusField button {
	border-radius: 20px;
}

.retryBtn {
	min-width: 40%;
}

.titleRightPanel {
	float: right;
	display: flex;
	justify-content: right;
	flex-direction: column;
}

.titleActionBtnContainer {
	display: block;
	display: flex;
	justify-content: right;
	min-height: 1.8vw;
}

.saveDraftBtn {
	padding: 1.5vh 1.75vh !important;
}

.titleActionTextContainer {
	display: block;
}

.titleActionText {
	color: #8b9095;
	font-size: 0.8vw;
	margin-top: 5px;
}

.titleStatusLabel {
	font-size: 0.8vw;
	width: 11vw;
	text-align: left;
	margin-right: 5px;
}

.titleStatusContainer {
	display: flex;
	align-items: center;
	justify-content: left;
	margin-top: 5px;
}

.titleLeftPanel {
	float: left;
	display: flex;
	justify-content: left;
	flex-direction: column;
}

.titleTextContainer {
	font-size: 1.4vw;
	text-align: left;
}

.footerEdit {
	display: flex;
	justify-content: flex-end;
	padding: 0.1vmax;
	position: sticky;
	bottom: 0;
}

.footerApproval {
	overflow: auto;
	padding: 0.1vmax;
	position: sticky;
	bottom: 0;
}

.navigationBtnContainer {
	float: left;
}

.footer {
	overflow: auto;
	overflow-y: hidden;
}

.footerLeftPanel {
	float: left;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.footerRightPanel {
	float: right;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.bodyContent {
	/* padding-bottom: 10vh; */
}

.customHeading {
	font-size: 16px;
	font-weight: bold;
}

.doneButton {
	width: clamp(50px, 10vw, 120px);
}

.subtext {
	margin-top: 2vh;
	font-size: 0.6vw;
}

.subTextNext {
	margin-bottom: 2vh;
	font-size: 0.6vw;
}

.contactDetailsErrorCloseBtn {
	min-width: 40%;
}

.backBtn {
	position: absolute;
	top: 5%;
	left: 10px;
	min-width: 5vw;
}

.btnIcon {
	width: 15px;
	height: 15px;
	margin-right: 4px;
	margin-left: 4px;
}

.btn {
	padding: 19px 6px !important;
	min-width: 120px !important;
	font-size: 13px !important;
	word-wrap: break-word;
	border-radius: 4px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
}

.btn:hover .btnIcon :global(path),
.btn:active .btnIcon :global(path),
.btn:focus .btnIcon :global(path) {
	fill: #ffffff;
}

.btnApprove,
.btnReject {
	background-color: #ffffff;
}

.btnApproveIcon,
.btnRejectIcon {
	margin-right: 10px;
}

.btnApprove,
.btnApprove:focus,
.btnApprove:active,
.btnApprove:hover {
	color: #14bb02;
	border: 1px solid #14bb02;
}

.btnReject,
.btnReject:focus,
.btnReject:active,
.btnReject:hover {
	color: #d93025;
	border: 1px solid #d93025;
}

.saveModal,
.approvalModal,
.rejectionModal {
}

.saveModalContainer,
.approvalModalContainer,
.rejectionModalContainer {
	width: 400px !important;
}

.saveModalHeaderContainer,
.approvalModalHeaderContainer,
.rejectionModalHeaderContainer {
}

.saveModalBodyHeader,
.approvalModalBodyHeader,
.rejectionModalBodyHeader {
	padding: 15px;
	text-align: center;
	display: block;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	font-family: Poppins-Regular;
}

.saveModalBodyContent,
.approvalModalBodyContent,
.rejectionModalBodyContent {
	padding: 0 15px 15px 15px;
}

.submitRemarks,
.approvalRemarks,
.rejectionRemarks {
	resize: none;
	height: 100px;
}

.saveModalBodyContent :global(.slds-form-element__label),
.approvalModalBodyContent :global(.slds-form-element__label),
.rejectionModalBodyContent :global(.slds-form-element__label) {
	font-size: 11px !important;
	font-family: Poppins-Regular;
	font-weight: 400 !important;
}

.submitRemarksSubLabel,
.approvalRemarksSubLabel,
.rejectionRemarksSubLabel {
	font-size: 11px;
	font-family: Poppins-Regular;
	color: #8b9095 !important;
}

.saveModalFooter,
.approvalModalFooter,
.rejectionModalFooter {
	padding-top: 5px;
	padding-bottom: 5px;
}

.saveModalBtn,
.approvalModalBtn,
.rejectionModalBtn {
	padding: 19px 6px !important;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.approveHeading {
	top: 250px;
	left: 602px;
	font-size: 14px;
	font-weight: 600;
	font-stretch: condensed;
}
