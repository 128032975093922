.headerContainer {
	border: none;
	padding-bottom: 0;
}

.modal {
	font-family: var(--common-font);
	overflow-y: hidden;
}

.header {
	overflow: auto;
	overflow-y: hidden;
	padding-top: 1vmax;
	padding-left: 0.5vmax;
	padding-right: 0.5vmax;
}

.titleActionBtnContainer {
	display: block;
	display: flex;
	justify-content: right;
	min-height: 1.8vw;
}

.btn {
	padding: 19px 6px !important;
	min-width: 120px !important;
	font-size: 13px !important;
	word-wrap: break-word;
	border-radius: 4px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
}

.btn:hover .btnIcon :global(path),
.btn:active .btnIcon :global(path),
.btn:focus .btnIcon :global(path) {
	fill: #ffffff;
}

.titleActionTextContainer {
	display: block;
}

.titleActionText {
	color: #8b9095;
	font-size: 0.8vw;
	margin-top: 5px;
}

.titleRightPanel {
	float: right;
	display: flex;
	justify-content: right;
	flex-direction: column;
}

.titleLeftPanel {
	float: left;
	display: flex;
	justify-content: left;
	flex-direction: column;
}

.footer {
	overflow: auto;
	overflow-y: hidden;
}

.footerSpaceBetween {
	overflow: auto;
	overflow-y: hidden;
	display: flex;
	justify-content: space-between;
}

.footerRightPanel {
	float: right;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.btnApprove,
.btnReject {
	background-color: #ffffff;
}

.btnApprove,
.btnApprove:focus,
.btnApprove:active,
.btnApprove:hover {
	color: #14bb02;
	border: 1px solid #14bb02;
}

.btnReject,
.btnReject:focus,
.btnReject:active,
.btnReject:hover {
	color: #d93025;
	border: 1px solid #d93025;
}

.btnApproveIcon,
.btnRejectIcon {
	margin-right: 10px;
}

.displayNone {
	display: none;
}

.channelStatus {
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	width: 300px;
	font-size: small;
}
.channelStatus p {
	width: 110px;
	text-align: left;
}
.statusPill {
	width: fit-content;
}
.titleTextContainer {
	width: fit-content;
}

.confirmTitle {
	font-size: 12pt;
	font-weight: bold;
}

.confirmBody {
	margin-top: 2vh;
	margin-bottom: 3vh;
}

.containerWidth {
	align-self: center;
	top: 0vh;
	max-width: 420px;
	width: 80%;
	text-align: center;
}

.bodyHeader {
	padding: 0 15px 0 15px;
	text-align: center;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	font-family: Poppins-Regular;
	margin-top: 1vh;
	height: 90px;
	display: flex;
}
