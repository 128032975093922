.modalContainer {
	align-self: center;
}

.headerContainer {
	border: none;
	padding-bottom: 0;
}

.header {
	float: left;
	display: flex;
	overflow: auto;
	overflow-y: hidden;
	padding-top: 1vmax;
	padding-left: 0.5vmax;
	padding-right: 0.5vmax;

	text-align: left;
	letter-spacing: 0px;
	color: #1e1e1e;
	opacity: 1;
}

.action {
	color: var(--orange);
	font-weight: bold;
	text-decoration: underline;
	cursor: pointer;
	display: inline-block;
}

.actionContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.customModalHeader {
	padding: 1vmax;
}

.errorBodyContent {
	padding-left: 15px;
	padding-right: 15px;
}

.errorHeader {
	font-weight: bold;
	margin-bottom: 10px;
	font: normal normal 1.2vmax Poppins-Light;
}

.errorBody {
	font: normal normal 10px/16px Poppins-Light;
}

.errorFooter {
	margin-top: 0;
	margin-bottom: 30px;
	font: normal normal 10px/16px Poppins-Light;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}
