.paperContainer {
	padding: 1vmax;
	margin-top: 41px;
	border-radius: 10px;
}


.title {
	color: var(--orange);
	font-size: 1.2vw;
	font: normal 600 24px/24px var(--alt-font);
	letter-spacing: 0.74px;
}

.mainContainer {
	margin-top: 13px;
}

