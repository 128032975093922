@media screen and (max-width: 1920px) and (min-width: 1367px) {
	.expandedCheckbox {
		padding-top: -5vh;
		padding-bottom: -5vh;
		padding-left: 15.5vw;
		margin-top: -3vh;
		margin-left: 21vw;
		margin-bottom: -2vh;
		margin-right: 10vw;
	}
	.subCheckbox {
		margin-bottom: 3vh;
		margin-top: -2vh;
		margin-left: -35.5vw;
		font: italic normal normal 10px/16px Poppins;
	}
	.var {
		border-bottom: 1px solid #00000024;
		margin-bottom: 1vh;
		margin-right: -18.7vw;
	}
}
@media (max-width: 1366px) {
	.expandedCheckbox {
		padding-bottom: -5vh;
		padding-left: 15.5vw;
		margin-top: -4vh;
		margin-left: 36.5vw;
		margin-bottom: -2vh;
		margin-right: 20vw;
	}
	.subCheckbox {
		margin-bottom: 3vh;
		margin-top: -3vh;
		margin-left: -50.5vw;
		font: italic normal normal 10px/16px Poppins;
	}
	.var {
		border-bottom: 1px solid #00000024;
		margin-bottom: 1vh;
		margin-right: -26.8vw;
	}
}
.subtext {
	color: #8b9095;
}
.checkbox.checked :global(.slds-checkbox_faux:after) {
	border-color: white !important;
}

.checkbox.checked :global(.slds-checkbox_faux) {
	background-color: var(--orange) !important;
}
