.warningIcon {
	fill: var(--orange);
	width: 4.6vw;
    margin-top: 15px;
}


.warningSubTextContainer{
	width: 320px !important;
}

.warningSubText {
	line-height: 20px;
	width: 230px;
	display: inline-block;
	font-family: Poppins-Regular;
}

.warningErrorCloseBtn {
	min-width: 40%;
    margin-bottom: 12px;
	font-size: 12px !important;
}