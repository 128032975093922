.innerButtons {
	padding-top: 2.2em;
}

.iconBtn {
	width: 1vw;
	height: 1vw;
}

.rowBreak {
	word-break: break-all;
}

.downloadDropdown {
	opacity: 0;
	pointer-events: none;
	position: fixed;
	right: 2%;
	top: 12%;
}

.downloadContainer {
	position: relative;
	margin: 0;
	padding: 0;
	align-items: right;
	align-items: right;
}

.errorBody {
	font-size: 0.8vmax;
}

.errorFooter {
	margin-top: 1em;
	font-size: 0.7vw;
	color: #3e3f42;
}

.filters > div,
.innerButtons > div {
	padding-left: 0.2em;
	padding-right: 0.2em;
}

.label {
	margin-top: 0.3vw !important;
	margin-bottom: 0.1vw !important;
	font-size: 0.79vw !important;
	color: #3b3b3b;
	opacity: 1;
}

.actionContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.action {
	color: var(--orange);
	font-weight: bold;
	text-decoration: underline;
	cursor: pointer;
	display: inline-block;
}

.inputIcon {
	position: absolute !important;
	top: 1.1vw !important;
	right: 0.7vw !important;
	max-width: 0.79vw !important;
	max-height: 0.79vw !important;
}

.textField {
	padding: 0.2vmax !important;
}

.errorBodyContent {
	padding-left: 15px;
	padding-right: 15px;
}

.errorHeader {
	font-weight: bold;
	margin-bottom: 10px;
	font: normal normal 1.2vmax Poppins-Light;
}

.errorBody {
	font: normal normal 10px/16px Poppins-Light;
}

.errorFooter {
	margin-top: 0;
	margin-bottom: 30px;
	font: normal normal 10px/16px Poppins-Light;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.buttons {
	margin-top: 1.35vmax !important;
	padding: 0.5vw !important;
	font-size: 0.7vmax !important;
}

.textFieldWithIcon {
	height: auto !important;
}

.dateField :global(.slds-form-element__control) :global(.slds-input) {
	height: 1.7vmax !important;
	font-size: 0.7vmax !important;
	padding-right: 0 !important;
}

.lookupTextField
	:global(.lookupPrefix__control)
	:global(.lookupPrefix__value-container) {
	padding-right: 2px !important;
	padding-left: 5px !important;
	height: 1.63vmax !important;
	font-size: 0.68vmax !important;
	color: #3b3b3b;
	opacity: 1;
	max-width: 105px;
}

.lookupTextLabel {
	margin-top: 0.25vmax !important;
	margin-bottom: 0 !important;
	font-size: 0.7vmax !important;
	color: #3b3b3b;
	opacity: 1;
}

@media screen and (max-width: 2500px) {
	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__value-container),
	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__indicators) {
		height: 1.8vmax !important;
	}

	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__value-container)
		:global(.lookupPrefix__placeholder) {
		font-size: 0.7vmax !important;
	}

	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__value-container) {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		font-size: 0.7vmax !important;
	}

	.lookupTextLabel div {
		margin-top: 0 !important;
		margin-bottom: 0.07vmax !important;
		font-size: 0.8vmax !important;
		color: #3b3b3b;
		opacity: 1;
	}

	.textFieldWithIcon {
		height: 1.8vmax !important;
	}

	.dateField :global(.slds-form-element__control) :global(.slds-input) {
		height: 1.7vmax !important;
		font-size: 0.7vmax !important;
		padding-right: 0 !important;
	}

	.buttons {
		margin-top: 1.55vmax !important;
		padding: 0.5vw !important;
		font-size: 0.7vmax !important;
	}
}

@media screen and (max-width: 1330px) {
	.textFieldWithIcon {
		height: 1.8vmax !important;
	}

	.dateField :global(.slds-form-element__control) :global(.slds-input) {
		height: 1.7vmax !important;
		font-size: 0.7vmax !important;
		padding-right: 0 !important;
	}

	.buttons {
		margin-top: 1.7vmax !important;
		padding: 0.5vw !important;
		font-size: 0.7vmax !important;
	}
}
