.title {
	font-family: var(--common-font-bold);
	font-size: 1.2vw;
	max-width: 50vw;
	overflow-wrap: break-word;
	margin-bottom: 2vh;
}

.select :global(.slds-button) {
	font-size: 0.7vw !important;
	line-height: 0.7vw !important;
	border-radius: 16px;
	color: white !important;
	padding: 0.5vh 0.4vw !important;
	height: 15px;
	background-color: #8b9095;
}

.active :global(.slds-button) {
	background-color: #14bb02;
}

.active a,
.active a:hover {
	color: #14bb02;
}

.statusLabel {
	font: normal 0.8vw Poppins-Regular;
}

.titleActionTextContainer {
	display: block;
}

.titleActionText {
	color: #8b9095;
	font-size: 0.8vw;
	margin-top: 5px;
}

.linkBtn {
	border: none !important;
	padding-right: 0;
	color: orange !important;
	font: bold 12px Poppins-Regular !important;
	background: none !important;
}

.edit svg {
	margin-right: 0.5vw;
	width: 1vmax;
	height: 1vmax;
}

.btn {
	padding: 19px 6px !important;
	min-width: 120px !important;
	font-size: 13px !important;
	word-wrap: break-word;
	border-radius: 4px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
}

.confirmationHeader {
	font-size: 18px;
}

.subtext {
	margin-top: 2vh;
	font-size: 0.7vw;
}

.successBtn {
	padding: 19px 6px !important;
	min-width: 120px !important;
	font-size: 13px !important;
	height: 40px;
	border-radius: 4px;
}

.header {
	font-size: 1.25rem;
	line-height: 1.25;
}

.containerWidth {
	align-self: center;
	top: 0vh;
	max-width: 420px;
	width: 80%;
	text-align: center;
}

.labelClassName {
	font-size: 13px !important;
}

.bodyContent {
	text-align: start;
	font-family: var(--common-font);
	padding: 0 15px 15px 15px;
	height: 180px;
}

.bodyHeader {
	padding: 15px 15px 0 15px;
	text-align: center;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	font-family: Poppins-Regular;
	margin-top: 1vh;
}

.bodyContent :global(.slds-form-element__label) {
	font-size: 13px !important;
	font-family: Poppins-Regular;
	font-weight: 400 !important;
}

.remarksSubLabel {
	font-size: 0.7vw;
	font-family: Poppins-Regular;
	color: #8b9095 !important;
}

.status {
	width: 125px;
}
